/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next_question': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<defs><filter x="-10.4%" y="-10.4%" width="129.2%" height="129.2%" filterUnits="objectBoundingBox" id="svgicon_next_question_a"><feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.0784313725 0 0 0 0 0.0980392157 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1"/></filter><circle pid="0" id="svgicon_next_question_b" cx="12" cy="12" r="12"/></defs><g _fill="none" fill-rule="evenodd"><g transform="rotate(-90 14 12)"><use _fill="#000" filter="url(#svgicon_next_question_a)" xlink:href="#svgicon_next_question_b"/><use _fill="#1B2147" xlink:href="#svgicon_next_question_b"/></g><path pid="1" d="M13 18.243l4.066-4.066a.25.25 0 000-.354L13 9.757" _stroke="#F9F9F9" stroke-width="2" stroke-linecap="round"/></g>'
  }
})
